<template>
  <div>
    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: walletTransactions.limit,
        count: walletTransactions.count,
        page: walletTransactions.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row class="w-100">
          <b-col
            md="4"
            xl="4"
            class="mb-1"
          >
            <AssetFilter
              v-model="selectAsset"
              @change="changeAsset"
            />
          </b-col>

          <b-col
            md="4"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Type"
              label-for="type-active"
            >
              <v-select
                id="type-active"
                v-model="selectType"
                label="label"
                placeholder="Select status"
                :options="transactionTypesSelect"
                :reduce="option => option.value"
                @input="changeType"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
            class="mb-1"
          >
            <b-form-group>
              <label for="datepicker-placeholder">Status</label>
              <b-form-select
                v-model="selectStatus"
                :options="statusOptions"
                @change="changeStatus"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search by address</label>
              <b-form-input
                v-model="userAddress"
                placeholder="address"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search by name</label>
              <b-form-input
                v-model="userTelegramName"
                placeholder="name"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="1"
            xl="1"
            class="mb-1 mt-auto pb-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <span
          v-if="column.field === 'amount'"
          class="flex-center-align"
          :class="row.amount.theme"
        >
          {{ row.amount.text }}
        </span>

        <span
          v-else-if="column.field === 'user'"
          class="flex-center-align "
        >
          <b-button
            variant="link"
            target="_blank"
            class="text-left"
            @click="row.user.action"
          >
            {{ row.user.name }}
          </b-button>
        </span>

        <span
          v-else-if="column.field === 'type'"
          class="flex-center-align"
        >
          {{ getTypeName(row.type) || '' }}
        </span>

        <span
          v-else-if="column.field === 'asset'"
          class="flex-center-align"
        >
          <img
            :src="row.asset.image"
            alt="coin"
            class="coin-img"
          >
          <span class="ml-1">{{ row.asset.text }}</span>
        </span>

        <span
          v-else-if="column.field === 'status'"
          class="flex-center-align"
        >
          <b-badge :variant="row.status.theme">
            {{ row.status.text }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'riskLevel'" class="flex-center-align">
          <b-badge :variant="getRiskLevelConfig(row).color" class="text-white">
            {{ getRiskLevelConfig(row).text }}
          </b-badge>
        </span>

        <span
          v-else-if="column.field === 'toAddress'"
          class="flex-center-align"
        >
          <!-- <b-button variant="link" @click="doCopy(row.toAddress.raw)" class="p-1 font-16 text-left">
            {{ row.toAddress.text }}
          </b-button> -->
          <span
            class="p-1 font-16 text-left text-primary cursor-pointer"
            @click="doCopy(row.toAddress.raw)"
          >
            {{ row.toAddress.text }}
          </span>
        </span>

        <span
          v-else-if="column.field === 'checkUrl'"
          class="flex-center-align"
        >
          <a
            :href="row.checkUrl.raw"
            target="_blank"
            class="p-1 "
          >{{ row.checkUrl.text }}</a>
        </span>

        <!-- default render cell -->
        <span
          v-else
          class="flex-center-align"
        >
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BFormSelect, BBadge, BCol, BFormGroup, BFormInput, BButton, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import vSelect from 'vue-select'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import transactions from '@/mixins/transactions'

import { transactionPages, transactionTypes, transactionTypesSelect } from './config/transactionTypes'
import AppTable from '@/components/AppTable.vue'
import AssetFilter from '@/components/containers/AssetFilter.vue'

export default {
  name: 'WalletTransaction',
  components: {
    AssetFilter,
    AppTable,
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BBadge,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, transactions],

  data() {
    return {
      columns,
    }
  },

  computed: {
    transactionTypes() {
      return transactionTypes
    },
    ...mapGetters({
      transactions: 'transactions/transactions',
    }),

    walletTransactions() {
      return this.transactions[transactionPages.wallet]
    },

    transactionTypesSelect() {
      return transactionTypesSelect
    },

    rows() {
      return this.walletTransactions?.value?.map(this.transactionsDataAdapter)
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchTransactions: 'transactions/fetchTransactions',
      fetchCryptoCurrencyById: 'cryptoCurrency/fetchCryptoCurrencyById',
    }),

    async initState() {
      const { address = '', assetId = '' } = this.$route.query
      if (address) this.userAddress = address
      if (assetId) {
        this.selectAsset = await this.preselectAsset(assetId)
      }

      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      await this.getTransactions(query)
      await this.getCurrency()
    },

    createQueryConfig(config) {
      return {
        page: this.walletTransactions.page,
        limit: this.walletTransactions.limit,

        userAddress: this.userAddress || undefined,
        userTelegramName: this.userTelegramName || undefined,
        coinId: this.selectAsset.id || undefined,
        type: this.selectType || undefined,
        status: this.selectStatus || undefined,

        ...config,
      }
    },

    getTransactions(query) {
      return this.waitRequest(() => this.fetchTransactions({ ...query, pageType: 'wallet' }).catch(this.checkErrors))
    },

    getAsset(id) {
      return this.waitRequest(() => this.fetchCryptoCurrencyById(id).catch(this.checkErrors))
    },

    async preselectAsset(assetId) {
      const response = await this.getAsset(assetId)
      const { name, network } = response.data
      const labelName = `${name} ${network ? `/ ${network}` : ''}`

      return { ...response.data, labelName }
    },

    getTypeName(name) {
      return this.transactionTypesSelect.filter(el => el.value === name).pop()?.label
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

.centered-element {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.font-16 {
  font-size: 1.1rem;
}

.coin-img {
  width: 30px;
  border-radius: 50%;
}
</style>
